import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  CircularProgress,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import DashboardHeader from "../../dashboard-components/DashboardHeader";

function DashboardEditProperty(props) {
  const { propertyId } = useParams(); // Get propertyId from the URL
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    country: "",
    city: "",
    address: "",
    exactAddress: "",
    wifiInfo: "",
    apartmentLayout: "",
    checkinCheckout: "",
    emergencyContacts: "",
    noisePolicy: "",
    smokingPolicy: "",
    petPolicy: "",
    trashRecycling: "",
    keyAccess: "",
    applianceInstructions: "",
    groceryStores: "",
    pharmacyLocations: "",
    localRestaurants: "",
    atmCurrency: "",
    publicTransport: "",
    taxiServices: "",
    laundryServices: "",
    groceryDelivery: "",
    emergencyNumbers: "",
    localAttractions: "",
    parks: "",
    gyms: "",
    buildingSecurity: "",
    fireEscapePlan: "",
    checkOutProcedure: "",
    depositRefund: "",
    reviewRequest: "",
  });
  const [loading, setLoading] = useState(true);
  const [submitStatus, setSubmitStatus] = useState(null);

  useEffect(() => {
    const fetchPropertyData = async () => {
      try {
        // const response = await axios.get(`http://localhost:5000/api/property/${propertyId}`);
        const response = await axios.get(
          `https://hoster-store-439319.ew.r.appspot.com/api/property/${propertyId}`
        );
        setFormData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching property data:", error);
        setLoading(false);
      }
    };

    fetchPropertyData();
  }, [propertyId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle location change (city-country combined)
  const handleLocationChange = (e) => {
    const [selectedCity, selectedCountry] = e.target.value.split("-");
    setFormData({
      ...formData,
      city: selectedCity.trim(),
      country: selectedCountry.trim(),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitStatus(null); // Reset the status before submitting

    try {
      // await axios.patch(`http://localhost:5000/api/update-property/${propertyId}`, formData);
      await axios.patch(
        `https://hoster-store-439319.ew.r.appspot.com/api/update-property/${propertyId}`,
        formData
      );
      setSubmitStatus({
        success: true,
        message: "Store updated successfully!",
      });
      setTimeout(() => {
        setSubmitStatus(null);
        navigate("/"); // Redirect back to the property list
      }, 3000);
    } catch (error) {
      setSubmitStatus({
        success: false,
        message: "Failed to update store.",
      });
    }
  };

  return (
    <>
      <DashboardHeader hostData={props.hostData} />
      <div className="dashboard">
        <div className="dashboard__section">
          <Container sx={{ mt: 4 }}>
            <Typography variant="h4" sx={{ color: "#000", mb: 2 }}>
              Edit Store
            </Typography>

            <form
              onSubmit={handleSubmit}
              style={{ width: "90%", maxWidth: "800px" }}
            >
              {/* Property Location */}
              <Typography variant="h6" sx={{ mt: 2 }}>
                Property Location
              </Typography>

              <FormControl fullWidth margin="normal" required>
                <InputLabel id="location-label">City and Country</InputLabel>
                <Select
                  labelId="location-label"
                  name="location"
                  value={`${formData.city} - ${formData.country}`}
                  onChange={handleLocationChange}
                  label="City and Country"
                  sx={inputStyles}
                >
                  <MenuItem value="Vienna - Austria">Vienna - Austria</MenuItem>
                  <MenuItem value="Budapest - Hungary">
                    Budapest - Hungary
                  </MenuItem>
                </Select>
              </FormControl>

              <TextField
                label="Address"
                name="address"
                fullWidth
                value={formData.address}
                onChange={handleInputChange}
                margin="normal"
                required
                sx={inputStyles}
              />

              {/* Apartment Details */}
              <Typography variant="h6" sx={{ mt: 2 }}>
                Apartment Details
              </Typography>
              <TextField
                label="Exact Address"
                name="exactAddress"
                fullWidth
                value={formData.exactAddress}
                onChange={handleInputChange}
                margin="normal"
                sx={inputStyles}
              />
              <TextField
                label="Wi-Fi Information"
                name="wifiInfo"
                fullWidth
                value={formData.wifiInfo}
                onChange={handleInputChange}
                margin="normal"
                sx={inputStyles}
              />
              <TextField
                label="Apartment Size & Layout"
                name="apartmentLayout"
                fullWidth
                value={formData.apartmentLayout}
                onChange={handleInputChange}
                margin="normal"
                sx={inputStyles}
              />
              <TextField
                label="Check-in/Check-out Times"
                name="checkinCheckout"
                fullWidth
                value={formData.checkinCheckout}
                onChange={handleInputChange}
                margin="normal"
                sx={inputStyles}
              />
              <TextField
                label="Emergency Contacts"
                name="emergencyContacts"
                fullWidth
                value={formData.emergencyContacts}
                onChange={handleInputChange}
                margin="normal"
                sx={inputStyles}
              />

              {/* House Rules */}
              <Typography variant="h6" sx={{ mt: 2 }}>
                House Rules
              </Typography>
              <TextField
                label="Noise Policy"
                name="noisePolicy"
                fullWidth
                value={formData.noisePolicy}
                onChange={handleInputChange}
                margin="normal"
                sx={inputStyles}
              />
              <TextField
                label="Smoking Policy"
                name="smokingPolicy"
                fullWidth
                value={formData.smokingPolicy}
                onChange={handleInputChange}
                margin="normal"
                sx={inputStyles}
              />
              <TextField
                label="Pet Policy"
                name="petPolicy"
                fullWidth
                value={formData.petPolicy}
                onChange={handleInputChange}
                margin="normal"
                sx={inputStyles}
              />
              <TextField
                label="Trash & Recycling"
                name="trashRecycling"
                fullWidth
                value={formData.trashRecycling}
                onChange={handleInputChange}
                margin="normal"
                sx={inputStyles}
              />
              <TextField
                label="Key Access & Locking Instructions"
                name="keyAccess"
                fullWidth
                value={formData.keyAccess}
                onChange={handleInputChange}
                margin="normal"
                sx={inputStyles}
              />
              <TextField
                label="Appliance Instructions"
                name="applianceInstructions"
                fullWidth
                value={formData.applianceInstructions}
                onChange={handleInputChange}
                margin="normal"
                sx={inputStyles}
              />

              {/* Local Amenities */}
              <Typography variant="h6" sx={{ mt: 2 }}>
                Local Amenities
              </Typography>
              <TextField
                label="Nearest Grocery Stores"
                name="groceryStores"
                fullWidth
                value={formData.groceryStores}
                onChange={handleInputChange}
                margin="normal"
                sx={inputStyles}
              />
              <TextField
                label="Pharmacy Locations"
                name="pharmacyLocations"
                fullWidth
                value={formData.pharmacyLocations}
                onChange={handleInputChange}
                margin="normal"
                sx={inputStyles}
              />
              <TextField
                label="Local Restaurants"
                name="localRestaurants"
                fullWidth
                value={formData.localRestaurants}
                onChange={handleInputChange}
                margin="normal"
                sx={inputStyles}
              />
              <TextField
                label="ATM & Currency Exchange"
                name="atmCurrency"
                fullWidth
                value={formData.atmCurrency}
                onChange={handleInputChange}
                margin="normal"
                sx={inputStyles}
              />
              <TextField
                label="Public Transportation"
                name="publicTransport"
                fullWidth
                value={formData.publicTransport}
                onChange={handleInputChange}
                margin="normal"
                sx={inputStyles}
              />

              {/* Guest Services */}
              <Typography variant="h6" sx={{ mt: 2 }}>
                Guest Services
              </Typography>
              <TextField
                label="Taxi & Ride Services"
                name="taxiServices"
                fullWidth
                value={formData.taxiServices}
                onChange={handleInputChange}
                margin="normal"
                sx={inputStyles}
              />

              <TextField
                label="Laundry Services"
                name="laundryServices"
                fullWidth
                value={formData.laundryServices}
                onChange={handleInputChange}
                margin="normal"
                sx={inputStyles}
              />
              <TextField
                label="Grocery Delivery"
                name="groceryDelivery"
                fullWidth
                value={formData.groceryDelivery}
                onChange={handleInputChange}
                margin="normal"
                sx={inputStyles}
              />
              <TextField
                label="Emergency Numbers"
                name="emergencyNumbers"
                fullWidth
                value={formData.emergencyNumbers}
                onChange={handleInputChange}
                margin="normal"
                sx={inputStyles}
              />

              {/* Neighborhood Information */}
              <Typography variant="h6" sx={{ mt: 2 }}>
                Neighborhood Information
              </Typography>
              <TextField
                label="Local Attractions"
                name="localAttractions"
                fullWidth
                value={formData.localAttractions}
                onChange={handleInputChange}
                margin="normal"
                sx={inputStyles}
              />
              <TextField
                label="Parks & Outdoor Spaces"
                name="parks"
                fullWidth
                value={formData.parks}
                onChange={handleInputChange}
                margin="normal"
                sx={inputStyles}
              />
              <TextField
                label="Gyms or Fitness Centers"
                name="gyms"
                fullWidth
                value={formData.gyms}
                onChange={handleInputChange}
                margin="normal"
                sx={inputStyles}
              />

              {/* Safety & Security */}
              <Typography variant="h6" sx={{ mt: 2 }}>
                Safety & Security
              </Typography>
              <TextField
                label="Building Security"
                name="buildingSecurity"
                fullWidth
                value={formData.buildingSecurity}
                onChange={handleInputChange}
                margin="normal"
                sx={inputStyles}
              />
              <TextField
                label="Fire Escape Plan"
                name="fireEscapePlan"
                fullWidth
                value={formData.fireEscapePlan}
                onChange={handleInputChange}
                margin="normal"
                sx={inputStyles}
              />

              {/* Checkout Instructions */}
              <Typography variant="h6" sx={{ mt: 2 }}>
                Checkout Instructions
              </Typography>
              <TextField
                label="Check-Out Procedure"
                name="checkOutProcedure"
                fullWidth
                value={formData.checkOutProcedure}
                onChange={handleInputChange}
                margin="normal"
                sx={inputStyles}
              />
              <TextField
                label="Deposit Refund"
                name="depositRefund"
                fullWidth
                value={formData.depositRefund}
                onChange={handleInputChange}
                margin="normal"
                sx={inputStyles}
              />
              <TextField
                label="Review Request"
                name="reviewRequest"
                fullWidth
                value={formData.reviewRequest}
                onChange={handleInputChange}
                margin="normal"
                sx={inputStyles}
              />

              {/* Submit Button */}
              <Box
                sx={{ textAlign: "center", mt: 3, mb: 3, maxWidth: "260px" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  fullWidth
                  sx={{ backgroundColor: "#f89646", color: "#fff" }}
                >
                  Save Changes
                </Button>

                {submitStatus && (
                  <Typography
                    variant="body2"
                    color={submitStatus.success ? "green" : "red"}
                    sx={{ mt: 2 }}
                  >
                    {submitStatus.message}
                  </Typography>
                )}
              </Box>
            </form>
          </Container>
          {/* Loading indicator for properties */}
          {loading && (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
              <CircularProgress />
            </Box>
          )}
        </div>
      </div>
    </>
  );
}

export default DashboardEditProperty;

// Styles for input fields
const inputStyles = {
  backgroundColor: "#f7fafa",
  borderRadius: "5px",
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#6bb7be", // Secondary focus border color
    },
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#6bb7be", // Secondary focus label color
  },
};
