import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import "./App.css";

// Import MUI
import Typography from "@mui/material/Typography";

// Imports general
import HostersInfo from "./pages/HostersInfo";

// Imports store
import StoreWelcome from "./store-pages/StoreWelcome";
import StoreExperiences from "./store-pages/StoreExperiences";

// Imports dashboard
import DashboardHostSignUpForm from "./dashboard-pages/DashboardHostSignUpForm";
import DashboardHostProfile from "./dashboard-pages/DashboardHostProfile";
import DashboardHostProperties from "./dashboard-pages/DashboardHostProperties";
import DashboardCreateProperty from "./dashboard-pages/DashboardCreateProperty";
import DashboardHostProperty from "./dashboard-pages/DashboardHostProperty";
import DashboardEditProperty from "./dashboard-pages/DashboardEditProperty";
import DashboardIncomeStreams from "./dashboard-pages/DashboardIncomeStreams";
import DashboardIncomeOverview from "./dashboard-pages/DashboardIncomeOverview";

function App() {
  const { isLoading, isAuthenticated, user } = useAuth0();

  /* ***** START HANDLE HOST DATA ***** */
  const [isUserHostExistInDb, setIsUserHostExistInDb] = useState(false);
  const [isHostsLoadingReqDone, setIsHostsLoadingReqDone] = useState(false);
  const [hostData, setHostData] = useState(null);

  const getHostData = async () => {
    try {
      // const response = await axios.get("http://localhost:5000/api/hosts");
      const response = await axios.get(
        "https://hoster-store-439319.ew.r.appspot.com/api/hosts"
      );
      const hostsData = response.data;
      return hostsData;
    } catch (err) {
      console.error(`Error from getHostData: ${err}`);
      return [];
    }
  };

  const checkIfUserHostExistInDb = async () => {
    if (user?.email) {
      const hosts = await getHostData();
      const host = hosts.find((hostObj) => hostObj.email === user.email);
      if (host) {
        setHostData(host);
        setIsUserHostExistInDb(true);
      } else {
        setIsUserHostExistInDb(false);
      }
    }
    setIsHostsLoadingReqDone(true);
  };

  useEffect(() => {
    if (isAuthenticated) {
      checkIfUserHostExistInDb();
    }
  }, [isAuthenticated, user]);

  if (isLoading) {
    // Show loading state while the app is fetching authentication and host data
    return (
      <div style={{ textAlign: "center", marginTop: "40px" }}>
        <Typography variant="h4" component="h4" gutterBottom className="title">
          Loading...
        </Typography>
      </div>
    );
  }

  return (
    <div className="app">
      <Router>
        <Routes>
          {/* Store Routes (Accessible to everyone) */}
          <Route
            path="/store/:hostId/:propertyId/welcome"
            element={<StoreWelcome />}
          />
          <Route
            path="/store/:hostId/:propertyId/experiences"
            element={<StoreExperiences hostData={hostData} />}
          />

          {/* Website Route  */}
          {!isAuthenticated ? <Route path="/" element={<HostersInfo />} /> : ``}

          {/* Dashboard Routes (Available only for authenticated hosts) */}
          {isAuthenticated && isHostsLoadingReqDone && (
            <>
              {isUserHostExistInDb ? (
                <>
                  <Route
                    path="/dashboard-host-profile"
                    element={<DashboardHostProfile hostData={hostData} />}
                  />
                  <Route
                    path="/"
                    element={<DashboardHostProperties hostData={hostData} />}
                  />
                  <Route
                    path="/dashboard-create-property"
                    element={<DashboardCreateProperty hostData={hostData} />}
                  />
                  <Route
                    path="/view-property/:propertyId"
                    element={<DashboardHostProperty hostData={hostData} />}
                  />
                  <Route
                    path="/edit-property/:propertyId"
                    element={<DashboardEditProperty hostData={hostData} />}
                  />
                  <Route
                    path="/dashboard-income-streams"
                    element={<DashboardIncomeStreams hostData={hostData} />}
                  />
                  <Route
                    path="/dashboard-income-overview"
                    element={<DashboardIncomeOverview hostData={hostData} />}
                  />
                </>
              ) : (
                <Route path="*" element={<DashboardHostSignUpForm />} />
              )}
            </>
          )}

          {/* Not Found Page */}
          {isHostsLoadingReqDone ? (
            <Route path="*" element={<div>Not found page</div>} />
          ) : (
            ``
          )}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
