import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Grid,
  CircularProgress,
  IconButton,
  Tooltip,
  Link as MuiLink,
} from "@mui/material";
import { ContentCopy as CopyIcon } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import axios from "axios";
import DashboardHeader from "../../dashboard-components/DashboardHeader";

function DashboardHostProperty(props) {
  const { propertyId } = useParams(); // Get propertyId from URL
  const [property, setProperty] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [storeBalance, setStoreBalance] = useState(0);

  useEffect(() => {
    const fetchPropertyDetails = async () => {
      try {
        /*
        const response = await axios.get(
          `http://localhost:5000/api/property/${propertyId}`
        );
        */
        const response = await axios.get(
          `https://hoster-store-439319.ew.r.appspot.com/api/property/${propertyId}`
        );
        setProperty(response.data);
        setLoading(false);
      } catch (err) {
        setError("Failed to load property details");
        setLoading(false);
      }
    };

    const calculateStoreBalance = async () => {
      try {
        /*
        const earningsResponse = await axios.get(
          "http://localhost:5000/api/earnings"
        );
        const paymentsResponse = await axios.get(
          "http://localhost:5000/api/payments"
        );
        */
        const earningsResponse = await axios.get(
          "https://hoster-store-439319.ew.r.appspot.com/api/earnings"
        );
        const paymentsResponse = await axios.get(
          "https://hoster-store-439319.ew.r.appspot.com/api/payments"
        );

        // Filter earnings and payments related to this property
        const propertyEarnings = earningsResponse.data.filter(
          (earning) => earning.propertyId === propertyId
        );
        const propertyPayments = paymentsResponse.data.filter(
          (payment) => payment.hostId === props.hostData.hostId
        );

        // Calculate total earnings and total payments
        const totalEarnings = propertyEarnings.reduce(
          (acc, item) => acc + parseFloat(item.hostEarnings || 0),
          0
        );
        const totalPayments = propertyPayments.reduce(
          (acc, item) => acc + parseFloat(item.paymentAmount || 0),
          0
        );

        // Set the balance as total earnings minus total payments
        setStoreBalance(totalEarnings - totalPayments);
      } catch (err) {
        console.error("Error calculating store balance:", err);
      }
    };

    fetchPropertyDetails();
    calculateStoreBalance();
  }, [propertyId]);

  const handleCopyToClipboard = (url) => {
    navigator.clipboard.writeText(url);
    alert("Store URL copied to clipboard!");
  };

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }
  return (
    <>
      <DashboardHeader hostData={props.hostData} />
      <div className="dashboard">
        <div className="dashboard__section">
          <Container sx={{ mt: 4, mb: 3 }}>
            <Typography variant="h4" sx={{ color: "#000", mb: 2 }}>
              Store Details
            </Typography>

            {loading ? (
              <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
                <CircularProgress />
              </Box>
            ) : error ? (
              <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
                <Typography color="error">{error}</Typography>
              </Box>
            ) : (
              property && (
                <Grid container spacing={2}>
                  {/* Property Store Section */}
                  <Grid item xs={12} sx={{ mt: 4 }}>
                    <Typography variant="h6" sx={{ color: "#6bb7be" }}>
                      Rental Store
                    </Typography>
                    <Typography variant="body1">
                      <strong>Store URL:</strong>
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <MuiLink
                        href={`${window.location.origin}/store/${property.relatedToHostId}/${property.propertyId}/welcome`}
                        target="_blank"
                        rel="noopener"
                        sx={{ color: "#6bb7be", mr: 0, wordBreak: "break-all" }}
                      >
                        {`${window.location.origin}/store/${property.relatedToHostId}/${property.propertyId}/welcome`}
                      </MuiLink>
                      <Tooltip title="Copy to clipboard">
                        <IconButton
                          aria-label="copy"
                          onClick={() =>
                            handleCopyToClipboard(
                              `${window.location.origin}/store/${property.relatedToHostId}/${property.propertyId}/welcome`
                            )
                          }
                          sx={{ color: "#6bb7be" }}
                        >
                          <CopyIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>

                    <Typography variant="body1" sx={{ mt: 2 }}>
                      <strong>Balance:</strong> €{storeBalance.toFixed(2)}
                    </Typography>
                    {/*
                    <Typography variant="body2" sx={{ color: "#888" }}>
                      (Total earnings from services like GetYourGuide, Welcome
                      Pickups, etc.)
                    </Typography>
                    */}
                  </Grid>

                  {/* Property Location */}
                  <Grid item xs={12}>
                    <Typography variant="h6" sx={{ color: "#6bb7be" }}>
                      Property Location
                    </Typography>
                    <Typography>
                      <strong>Country:</strong> {property.country}
                    </Typography>
                    <Typography>
                      <strong>City:</strong> {property.city}
                    </Typography>
                    <Typography>
                      <strong>Address:</strong> {property.address}
                    </Typography>
                  </Grid>

                  {/* Apartment Details */}
                  <Grid item xs={12}>
                    <Typography variant="h6" sx={{ color: "#6bb7be", mt: 3 }}>
                      Apartment Details
                    </Typography>
                    <Typography>
                      <strong>Exact Address:</strong> {property.exactAddress}
                    </Typography>
                    <Typography>
                      <strong>Wi-Fi Info:</strong> {property.wifiInfo}
                    </Typography>
                    <Typography>
                      <strong>Apartment Size & Layout:</strong>{" "}
                      {property.apartmentLayout}
                    </Typography>
                    <Typography>
                      <strong>Check-in/Check-out Times:</strong>{" "}
                      {property.checkinCheckout}
                    </Typography>
                    <Typography>
                      <strong>Emergency Contacts:</strong>{" "}
                      {property.emergencyContacts}
                    </Typography>
                  </Grid>

                  {/* House Rules */}
                  <Grid item xs={12}>
                    <Typography variant="h6" sx={{ color: "#6bb7be", mt: 3 }}>
                      House Rules
                    </Typography>
                    <Typography>
                      <strong>Noise Policy:</strong> {property.noisePolicy}
                    </Typography>
                    <Typography>
                      <strong>Smoking Policy:</strong> {property.smokingPolicy}
                    </Typography>
                    <Typography>
                      <strong>Pet Policy:</strong> {property.petPolicy}
                    </Typography>
                    <Typography>
                      <strong>Trash & Recycling:</strong>{" "}
                      {property.trashRecycling}
                    </Typography>
                    <Typography>
                      <strong>Key Access & Locking Instructions:</strong>{" "}
                      {property.keyAccess}
                    </Typography>
                    <Typography>
                      <strong>Appliance Instructions:</strong>{" "}
                      {property.applianceInstructions}
                    </Typography>
                  </Grid>

                  {/* Local Amenities */}
                  <Grid item xs={12}>
                    <Typography variant="h6" sx={{ color: "#6bb7be", mt: 3 }}>
                      Local Amenities
                    </Typography>
                    <Typography>
                      <strong>Nearest Grocery Stores:</strong>{" "}
                      {property.groceryStores}
                    </Typography>
                    <Typography>
                      <strong>Pharmacy Locations:</strong>{" "}
                      {property.pharmacyLocations}
                    </Typography>
                    <Typography>
                      <strong>Local Restaurants:</strong>{" "}
                      {property.localRestaurants}
                    </Typography>
                    <Typography>
                      <strong>ATM & Currency Exchange:</strong>{" "}
                      {property.atmCurrency}
                    </Typography>
                    <Typography>
                      <strong>Public Transportation:</strong>{" "}
                      {property.publicTransport}
                    </Typography>
                  </Grid>

                  {/* Guest Services */}
                  <Grid item xs={12}>
                    <Typography variant="h6" sx={{ color: "#6bb7be", mt: 3 }}>
                      Guest Services
                    </Typography>
                    <Typography>
                      <strong>Taxi & Ride Services:</strong>{" "}
                      {property.taxiServices}
                    </Typography>
                    <Typography>
                      <strong>Laundry Services:</strong>{" "}
                      {property.laundryServices}
                    </Typography>
                    <Typography>
                      <strong>Grocery Delivery:</strong>{" "}
                      {property.groceryDelivery}
                    </Typography>
                    <Typography>
                      <strong>Emergency Numbers:</strong>{" "}
                      {property.emergencyNumbers}
                    </Typography>
                  </Grid>

                  {/* Neighborhood Information */}
                  <Grid item xs={12}>
                    <Typography variant="h6" sx={{ color: "#6bb7be", mt: 3 }}>
                      Neighborhood Information
                    </Typography>
                    <Typography>
                      <strong>Local Attractions:</strong>{" "}
                      {property.localAttractions}
                    </Typography>
                    <Typography>
                      <strong>Parks & Outdoor Spaces:</strong> {property.parks}
                    </Typography>
                    <Typography>
                      <strong>Gyms or Fitness Centers:</strong> {property.gyms}
                    </Typography>
                  </Grid>

                  {/* Safety & Security */}
                  <Grid item xs={12}>
                    <Typography variant="h6" sx={{ color: "#6bb7be", mt: 3 }}>
                      Safety & Security
                    </Typography>
                    <Typography>
                      <strong>Building Security:</strong>{" "}
                      {property.buildingSecurity}
                    </Typography>

                    <Typography>
                      <strong>Fire Escape Plan:</strong>{" "}
                      {property.fireEscapePlan}
                    </Typography>
                  </Grid>

                  {/* Checkout Instructions */}
                  <Grid item xs={12}>
                    <Typography variant="h6" sx={{ color: "#6bb7be", mt: 3 }}>
                      Checkout Instructions
                    </Typography>
                    <Typography>
                      <strong>Check-Out Procedure:</strong>{" "}
                      {property.checkOutProcedure}
                    </Typography>
                    <Typography>
                      <strong>Deposit Refund:</strong> {property.depositRefund}
                    </Typography>
                    <Typography>
                      <strong>Review Request:</strong> {property.reviewRequest}
                    </Typography>
                  </Grid>
                </Grid>
              )
            )}
          </Container>
        </div>
      </div>
    </>
  );
}

export default DashboardHostProperty;
