import React, { useRef } from "react";
import "./index.css";
import LogoImg from "../../img/logo-no-background.png";
import { useAuth0 } from "@auth0/auth0-react";

function Header(props) {
  const { loginWithRedirect } = useAuth0();
  // refSection
  // Function to handle the smooth scroll
  const scrollToSection = (sectionRef) => {
    sectionRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };
  return (
    <div className="header">
      <div className="header__content">
        <div className="header__logo">
          <img src={LogoImg} />
        </div>
        <div className="header__menu">
          <div
            className="header__register-btn"
            onClick={() => loginWithRedirect()}
          >
            <span>SignUp</span>
            {/*
             <svg width="1em" height="1em" viewBox="0 0 24 24">
              <path
                fill="#000"
                d="M5.692 19V9.946L2.604 12.25L2 11.458L12 4l4.346 3.223V5h1.885v3.639l3.788 2.819l-.603.792l-3.089-2.304V19h-5.096v-5.23h-2.462V19zm1-1H9.77v-5.23h4.462V18h3.096V9.21L12 5.256L6.692 9.21zm3.5-7.994h3.616q0-.704-.542-1.159q-.543-.455-1.266-.455t-1.265.453t-.543 1.16M9.77 18v-5.23h4.462V18v-5.23H9.769z"
              />
            </svg>
            */}

            <span> / Login</span>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Header;
