import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Avatar,
  Button,
  Tooltip,
  Container,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import "./index.css";

// Import images
import HosterStoreLogo from "../../img/logo-no-background.png";

function DashboardHeader(props) {
  const { user, logout } = useAuth0(); // Auth0 hook for getting user info and logout
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const pages = ["Your Rental Stores", "Income Streams", "Income Overview"];
  const links = [
    "/",
    "/dashboard-income-streams",
    "/dashboard-income-overview",
  ];

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: "#fff", color: "#000" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              display: { xs: "none", md: "flex" },
              mr: 2,
            }}
          >
            <Link to="/">
              <img
                src={HosterStoreLogo}
                alt="Hoster Store Logo"
                style={{ width: "150px" }}
              />
            </Link>
          </Typography>

          {/* Mobile menu */}
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="open navigation menu"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              keepMounted
              transformOrigin={{ vertical: "top", horizontal: "left" }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
            >
              {pages.map((page, index) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Link
                    to={links[index]}
                    style={{ color: "#000", textDecoration: "none" }}
                  >
                    <Typography textAlign="center">{page}</Typography>
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          {/* Desktop menu */}
          <Typography
            variant="h5"
            noWrap
            component="div"
            sx={{ display: { xs: "flex", md: "none" }, flexGrow: 1 }}
          >
            <Link to="/">
              <img
                src={HosterStoreLogo}
                alt="Hoster Store Logo"
                style={{ width: "150px" }}
              />
            </Link>
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page, index) => (
              <Button
                key={page}
                sx={{
                  my: 2,
                  color: "#000",
                  display: "block",
                  textTransform: "none",
                }}
              >
                <Link
                  to={links[index]}
                  style={{ color: "#000", textDecoration: "none" }}
                >
                  {page}
                </Link>
              </Button>
            ))}
          </Box>

          {/* User profile and logout */}
          <Box sx={{ flexGrow: 0, display: "flex", alignItems: "center" }}>
            {user && (
              <>
                <Tooltip title="Open Profile">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar
                      alt={user.name}
                      src={
                        props.hostData.profileImageUrl
                          ? props.hostData.profileImageUrl
                          : user.picture
                      }
                      sx={{ width: 40, height: 40, marginRight: "10px" }}
                    />
                  </IconButton>
                </Tooltip>

                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  keepMounted
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem onClick={handleCloseUserMenu}>
                    <Link
                      to="/dashboard-host-profile"
                      style={{ textDecoration: "none", color: "#000" }}
                    >
                      <Typography textAlign="center">Profile</Typography>
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={() => logout()}>
                    <Typography textAlign="center">Logout</Typography>
                  </MenuItem>
                </Menu>
              </>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default DashboardHeader;
