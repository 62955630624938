import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
  CircularProgress,
  Box,
  Link as MuiLink,
  Button,
  ListItemSecondaryAction,
  Tooltip,
  Modal,
  Paper,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as ViewIcon,
  ContentCopy as CopyIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// Import components
import DashboardHeader from "../../dashboard-components/DashboardHeader";

function DashboardHostProperties({ hostData }) {
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false); // Modal state
  const [selectedPropertyId, setSelectedPropertyId] = useState(null); // Store the property ID to delete
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        /*
        const response = await axios.get(
          "http://localhost:5000/api/host-properties"
        );
        */
        const response = await axios.get(
          "https://hoster-store-439319.ew.r.appspot.com/api/host-properties"
        );
        const filteredProperties = response.data.filter(
          (property) => property.relatedToHostId === hostData.hostId
        );
        setProperties(filteredProperties);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching properties:", err);
        setError("Failed to load properties");
        setLoading(false);
      }
    };
    fetchProperties();
  }, [hostData.hostId]);

  const handleCopyToClipboard = (url) => {
    navigator.clipboard.writeText(url);
    alert("Store URL copied to clipboard!");
  };

  const handleDeleteClick = (propertyId) => {
    setSelectedPropertyId(propertyId); // Set the property ID to delete
    setOpen(true); // Open the modal
  };

  const handleConfirmDelete = async () => {
    try {
      /*
      await axios.delete(
        `http://localhost:5000/api/delete-property/${selectedPropertyId}`
      );
      */
      await axios.delete(
        `https://hoster-store-439319.ew.r.appspot.com/api/delete-property/${selectedPropertyId}`
      );
      setProperties(
        properties.filter((p) => p.propertyId !== selectedPropertyId)
      ); // Update the state after deletion
      setOpen(false); // Close the modal
    } catch (error) {
      console.error("Error deleting property:", error);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <DashboardHeader hostData={hostData} />
      <div className="dashboard">
        <div className="dashboard__section">
          <Container>
            {/* Page Title and Create Store Button */}
            <Box
              sx={{
                mt: 4,
                mb: 3,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: { xs: "column", md: "row" }, // Stack on mobile
              }}
            >
              <Typography variant="h4" sx={{ color: "#000", mb: 2 }}>
                Your Rental Stores{" "}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate("/dashboard-create-property")}
                sx={{ backgroundColor: "#f89646", color: "#fff" }}
              >
                Create Rental Store
              </Button>
            </Box>

            {/* Properties List */}
            {properties.length > 0 ? (
              <List>
                {properties.map((property) => {
                  const storeUrl = `https://hosterstore.com/store/${hostData.hostId}/${property.propertyId}/welcome`;
                  return (
                    <ListItem
                      key={property.propertyId}
                      sx={{
                        mb: 2,
                        border: "1px solid #eee",
                        borderRadius: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexDirection: { xs: "column", md: "row" }, // Stack content on mobile
                        position: "relative",
                        paddingBottom: { xs: 6, md: 2 }, // Add extra padding at the bottom on mobile
                      }}
                    >
                      <ListItemText
                        primary={property.address}
                        secondary={
                          <>
                            {`${property.city}, ${property.country}`}
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                mt: 1,
                                flexWrap: "wrap", // Wrap link on small screens
                              }}
                            >
                              <MuiLink
                                href={storeUrl}
                                target="_blank"
                                rel="noopener"
                                sx={{
                                  color: "#6bb7be",
                                  wordBreak: "break-all", // Break link if too long
                                }}
                              >
                                {storeUrl}
                              </MuiLink>

                              <Tooltip title="Copy to clipboard">
                                <IconButton
                                  aria-label="copy"
                                  onClick={() =>
                                    handleCopyToClipboard(storeUrl)
                                  }
                                  sx={{ ml: 1, color: "#6bb7be" }}
                                >
                                  <CopyIcon />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </>
                        }
                      />
                      <ListItemSecondaryAction
                        sx={{
                          display: "flex",
                          alignItems: { xs: "end", md: "center" },
                          flexDirection: "row",
                          mt: { xs: 1, md: 0 },
                          justifyContent: { xs: "flex-end", md: "flex-start" },
                          position: { xs: "absolute", md: "absolute" },
                          right: { xs: 16, md: 16 },
                          bottom: { xs: 16, md: "50%" }, // Positioned 16px from bottom on mobile
                          transform: { md: "translateY(50%)" },
                        }}
                      >
                        <Tooltip title="View Property">
                          <IconButton
                            edge="end"
                            aria-label="view"
                            onClick={() =>
                              navigate(`/view-property/${property.propertyId}`)
                            }
                            sx={{ color: "#6bb7be" }} // Consistent color
                          >
                            <ViewIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Edit Property">
                          <IconButton
                            edge="end"
                            aria-label="edit"
                            onClick={() =>
                              navigate(`/edit-property/${property.propertyId}`)
                            }
                            sx={{ ml: 2, color: "#6bb7be" }} // Consistent color
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete Property">
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            sx={{ ml: 2, color: "#6bb7be" }} // Consistent secondary color
                            onClick={() =>
                              handleDeleteClick(property.propertyId)
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
              </List>
            ) : (
              <Typography>No properties found for this host.</Typography>
            )}
          </Container>
          {/* Loading indicator for properties */}
          {loading && (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
              <CircularProgress />
            </Box>
          )}
        </div>
      </div>

      {/* Modal for confirming delete */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="delete-confirmation-modal"
      >
        <Paper
          sx={{ p: 4, width: 400, margin: "100px auto", textAlign: "center" }}
        >
          <Typography variant="h6" id="delete-confirmation-modal">
            Are you sure you want to delete this store?
          </Typography>
          <Typography variant="body1" sx={{ mt: 2 }}>
            Store ID: {selectedPropertyId}
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "space-around", mt: 4 }}>
            <Button
              variant="contained"
              color="error"
              onClick={handleConfirmDelete}
            >
              Confirm
            </Button>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
          </Box>
        </Paper>
      </Modal>
    </>
  );
}

export default DashboardHostProperties;
