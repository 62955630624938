import "./index.css";
import React, { useState } from "react";

// Import MUI
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";

import { Link, useParams } from "react-router-dom";

// Import images
import HosterStoreLogo from "../../img/logo-no-background.png";

function StoreHeader() {
  const { hostId, propertyId } = useParams();
  /* START HANDLING HEADER MUI APP BAR */
  const pages = ["Welcome", "Tours and Activities", "Taxi"];
  const links = [
    `/store/${hostId}/${propertyId}/welcome`,
    `/store/${hostId}/${propertyId}/experiences`,
    `https://www.book-online-transfers.com/en/hosterstore-airport-taxi?sub_affiliate_id=${propertyId}`,
  ];

  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  /* END HANDLING HEADER MUI APP BAR */

  return (
    <div className="store-header">
      <AppBar position="static" sx={{ backgroundColor: "#fff", color: "#000" }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href={`/store/${hostId}/${propertyId}/welcome`}
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              <img src={HosterStoreLogo} style={{ width: "150px" }} />
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{ display: { xs: "block", md: "none" } }}
              >
                {pages.map((page, index) => (
                  <MenuItem key={page} onClick={handleCloseNavMenu}>
                    {page === "Taxi" ? (
                      <a
                        href={links[index]}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "#000", textDecoration: "none" }}
                      >
                        <Typography
                          sx={{ textAlign: "center" }}
                          style={{ textTransform: "none" }}
                        >
                          {page}
                        </Typography>
                      </a>
                    ) : (
                      <Link to={links[index]} style={{ color: "#000" }}>
                        <Typography
                          sx={{ textAlign: "center" }}
                          style={{ textTransform: "none" }}
                        >
                          {page}
                        </Typography>
                      </Link>
                    )}
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            <Typography
              variant="h5"
              noWrap
              component="a"
              href="#app-bar-with-responsive-menu"
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              <img src={HosterStoreLogo} style={{ width: "150px" }} />
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {pages.map((page, index) => (
                <Button
                  key={page}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: "white", display: "block" }}
                  style={{ textTransform: "none" }}
                >
                  {page === "Taxi" ? (
                    <a
                      href={links[index]}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "#000", textDecoration: "none" }}
                    >
                      {page}
                    </a>
                  ) : (
                    <Link style={{ color: "#000" }} to={links[index]}>
                      {page}
                    </Link>
                  )}
                </Button>
              ))}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}
export default StoreHeader;
// END
