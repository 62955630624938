import React, { useState } from "react";
import {
  Container,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  Card,
  CardContent,
  Box,
  Avatar,
} from "@mui/material";
import DashboardHeader from "../../dashboard-components/DashboardHeader";

// Sample logos for income streams
//import GetYourGuideLogo from "../../img/getyourguide_logo.svg";
//import WelcomePickupsLogo from "../../img/welcome_pickups_logo.svg";

// Sample data for income streams
const incomeStreamsData = {
  "Budapest - Hungary": [
    {
      id: 1,
      name: "GetYourGuide Affiliate Program",
      description:
        "Earn commissions on every tour or activity booking made by your guests. Tours in Budapest, day trips, and more.",
      logo: "https://res.cloudinary.com/dgil5kvos/image/upload/v1729495730/hosterstore/Suppliers%20Logos/getyourguide_logo_yznqk3.svg", // Use the logo image for each income stream
      commission: "6%-7% per booking",
      hostEarns: "3%-3.5% per booking",
    },
    {
      id: 2,
      name: "Welcome Pickups Taxi Service",
      description:
        "Offer reliable airport pickups and transfers to your guests. Earn commissions on every ride booked.",
      logo: "https://res.cloudinary.com/dgil5kvos/image/upload/v1729496843/hosterstore/Suppliers%20Logos/welcome_pickups_logo2_nora8k.png",
      commission: "€5 per ride",
      hostEarns: "€2.5 per ride",
    },
  ],
  "Vienna - Austria": [
    {
      id: 1,
      name: "GetYourGuide Affiliate Program",
      description:
        "Earn commissions on every tour or activity booking made by your guests. City tours in Vienna and day trips available.",
      logo: "https://res.cloudinary.com/dgil5kvos/image/upload/v1729495730/hosterstore/Suppliers%20Logos/getyourguide_logo_yznqk3.svg",
      commission: "6%-7% per booking",
      hostEarns: "3%-3.5% per booking",
    },
    {
      id: 2,
      name: "Welcome Pickups Taxi Service",
      description:
        "Reliable taxi services for airport pickups and city rides. Offer seamless transportation to your guests and earn commissions.",
      logo: "https://res.cloudinary.com/dgil5kvos/image/upload/v1729496843/hosterstore/Suppliers%20Logos/welcome_pickups_logo2_nora8k.png",
      commission: "€5 per ride",
      hostEarns: "€2.5 per ride",
    },
  ],
};

function DashboardIncomeStreams(props) {
  const [selectedCity, setSelectedCity] = useState("Budapest - Hungary");
  const incomeStreams = incomeStreamsData[selectedCity] || [];

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
  };

  return (
    <>
      <DashboardHeader hostData={props.hostData} />
      <div className="dashboard">
        <div className="dashboard__section">
          <Container sx={{ mt: 4 }}>
            <Typography variant="h4" sx={{ color: "#000", mb: 2 }}>
              Income Streams
            </Typography>
            <Typography
              variant="body1"
              sx={{
                backgroundColor: "#f9f9f9",
                padding: "10px",
                borderRadius: "8px",
                color: "#6bb7be",
                mb: 3,
              }}
            >
              The services displayed below are currently available for the
              selected city. We are always working to expand our income streams.
            </Typography>

            {/* Dropdown to select city */}
            <FormControl
              fullWidth
              sx={{
                mb: 3,
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#6bb7be", // Secondary focus border color
                  },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#6bb7be", // Secondary focus label color
                },
              }}
            >
              <InputLabel>Choose City</InputLabel>
              <Select value={selectedCity} onChange={handleCityChange}>
                <MenuItem value="Budapest - Hungary">
                  Budapest - Hungary
                </MenuItem>
                <MenuItem value="Vienna - Austria">Vienna - Austria</MenuItem>
              </Select>
            </FormControl>

            {/* Income Streams */}
            <Grid container spacing={4}>
              {incomeStreams.map((stream) => (
                <Grid item xs={12} sm={6} key={stream.id}>
                  <Card sx={{ backgroundColor: "#f7fafa" }}>
                    <CardContent>
                      {/* Display the logo instead of an image */}
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mb: 2,
                        }}
                      >
                        <Avatar
                          src={stream.logo}
                          alt={`${stream.name} Logo`}
                          sx={{
                            width: 64,
                            height: 64,
                            mr: 2,
                          }}
                        />
                        <Typography variant="h6" sx={{ color: "#f89646" }}>
                          {stream.name}
                        </Typography>
                      </Box>

                      <Typography
                        variant="body2"
                        sx={{ color: "black", mb: 1 }}
                      >
                        {stream.description}
                      </Typography>
                      {/*
                      <Typography
                        variant="body2"
                        sx={{ color: "black", mb: 1 }}
                      >
                        <strong>Commission Structure:</strong>{" "}
                        {stream.commission}
                      </Typography>
                          */}

                      <Typography variant="body2" sx={{ color: "black" }}>
                        <strong>You Earn:</strong> {stream.hostEarns}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>
        </div>
      </div>
    </>
  );
}

export default DashboardIncomeStreams;
