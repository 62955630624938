import React, { useState } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
} from "@mui/material";

const EarningsCalculator = () => {
  const [city, setCity] = useState("");
  const [guestCount, setGuestCount] = useState("");
  const [daysRented, setDaysRented] = useState("");
  const [earnings, setEarnings] = useState(null);
  const [earningsBreakdown, setEarningsBreakdown] = useState(null);

  // Constants for service adoption and commission rates
  const getYourGuideRate = 0.03; // 3% commission
  const welcomePickupsRate = 2.5; // €2.5 per booking
  const getYourGuideAdoption = 0.2; // 20% adoption rate for GetYourGuide
  const welcomePickupsAdoption = 0.1; // 10% adoption rate for Welcome Pickups

  const calculateEarnings = () => {
    if (!city || !guestCount || !daysRented) {
      alert("Please fill out all fields");
      return;
    }

    const totalGuests = guestCount * daysRented;

    // Calculate earnings for each service
    const getYourGuideEarnings =
      totalGuests * getYourGuideAdoption * 100 * getYourGuideRate; // Assuming average tour price is €100
    const welcomePickupsEarnings =
      totalGuests * welcomePickupsAdoption * welcomePickupsRate;

    const totalEarnings = getYourGuideEarnings + welcomePickupsEarnings;

    // Set earnings and breakdown
    setEarnings(totalEarnings.toFixed(2));
    setEarningsBreakdown({
      getYourGuide: getYourGuideEarnings.toFixed(2),
      welcomePickups: welcomePickupsEarnings.toFixed(2),
    });
  };

  return (
    <div className="earnings-calculator section">
      <Container
        sx={{ padding: "12px", textAlign: { xs: "center", sm: "left" } }}
      >
        <h1 className="hero__title" style={{ marginTop: 0 }}>
          <span>Estimate Your Earnings</span>
        </h1>
        <Typography variant="body1" gutterBottom>
          Calculate how much extra revenue you can earn by offering tours and
          taxi services to your guests in Budapest or Vienna.
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            maxWidth: 600,
          }}
        >
          <FormControl fullWidth>
            <InputLabel
              sx={{
                "&.Mui-focused": { color: "#6bb7be" }, // Label color on focus
              }}
            >
              City
            </InputLabel>
            <Select
              value={city}
              onChange={(e) => setCity(e.target.value)}
              label="City"
              required
              sx={{
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#6bb7be", // Border color on focus
                },
              }}
            >
              <MenuItem value="Budapest">Budapest</MenuItem>
              <MenuItem value="Vienna">Vienna</MenuItem>
            </Select>
          </FormControl>

          <TextField
            label="Average Number of Guests per Rental"
            type="number"
            value={guestCount}
            onChange={(e) => setGuestCount(e.target.value)}
            required
            fullWidth
            sx={{
              "& .MuiInputLabel-root.Mui-focused": { color: "#6bb7be" }, // Label color on focus
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#6bb7be", // Border color on focus
                },
            }}
          />

          <TextField
            label="Number of Days Rented per Month"
            type="number"
            value={daysRented}
            onChange={(e) => setDaysRented(e.target.value)}
            required
            fullWidth
            sx={{
              "& .MuiInputLabel-root.Mui-focused": { color: "#6bb7be" }, // Label color on focus
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#6bb7be", // Border color on focus
                },
            }}
          />

          <Button
            variant="contained"
            sx={{
              backgroundColor: "#f89646",
              color: "#fff",
              width: { xs: "100%", sm: 200 },
              mt: 2,
            }}
            onClick={calculateEarnings}
          >
            Calculate Earnings
          </Button>
        </Box>

        {earnings && (
          <Box
            sx={{
              marginTop: 4,
              backgroundColor: "#e0f7fa",
              padding: 3,
              borderRadius: 2,
            }}
          >
            <Typography variant="h5" color="#6bb7be">
              Estimated Monthly Earnings: €{earnings}
            </Typography>
            <Typography variant="body2" color="#888" sx={{ marginTop: 1 }}>
              <strong>Breakdown:</strong>
              <ul style={{ paddingLeft: "1em" }}>
                <li>
                  <Typography variant="body2">
                    <strong>Tours & Experiences (GetYourGuide):</strong> €
                    {earningsBreakdown.getYourGuide}– 3% commission on an
                    average booking price of €100, with an adoption rate of 20%.
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2">
                    <strong>Taxi Service (Welcome Pickups):</strong> €
                    {earningsBreakdown.welcomePickups}– €2.5 per booking with an
                    adoption rate of 10%.
                  </Typography>
                </li>
              </ul>
            </Typography>
          </Box>
        )}
      </Container>
    </div>
  );
};

export default EarningsCalculator;
