import React, { useState } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  CircularProgress,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import DashboardHeader from "../../dashboard-components/DashboardHeader";
import axios from "axios";

function DashboardCreateProperty(props) {
  const [formData, setFormData] = useState({
    // General
    propertyId: new Date().getTime(),
    relatedToHostId: props.hostData.hostId,
    creationDate: new Date(),

    // Property Location
    country: "",
    city: "",
    address: "",

    // Apartment Details
    exactAddress: "",
    wifiInfo: "",
    apartmentLayout: "",
    checkinCheckout: "",
    emergencyContacts: "",

    // House Rules
    noisePolicy: "",
    smokingPolicy: "",
    petPolicy: "",
    trashRecycling: "",
    keyAccess: "",
    applianceInstructions: "",

    // Local Amenities
    groceryStores: "",
    pharmacyLocations: "",
    localRestaurants: "",
    atmCurrency: "",
    publicTransport: "",

    // Guest Services
    taxiServices: "",
    laundryServices: "",
    groceryDelivery: "",
    emergencyNumbers: "",

    // Neighborhood Information
    localAttractions: "",
    parks: "",
    gyms: "",

    // Safety & Security
    buildingSecurity: "",
    fireEscapePlan: "",

    // Checkout Instructions
    checkOutProcedure: "",
    depositRefund: "",
    reviewRequest: "",
  });

  const [loading, setLoading] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle changes for city and country dropdown
  const handleLocationChange = (e) => {
    const [selectedCity, selectedCountry] = e.target.value.split("-");
    setFormData({
      ...formData,
      city: selectedCity.trim(),
      country: selectedCountry.trim(),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSubmitStatus(null); // Reset the status before submitting

    try {
      // const response = await axios.post("http://localhost:5000/api/add-property", formData);
      const response = await axios.post(
        "https://hoster-store-439319.ew.r.appspot.com/api/add-property",
        formData
      );
      setSubmitStatus({
        success: true,
        message: "Store created successfully!",
      });

      // Redirect after success
      setTimeout(() => {
        setSubmitStatus(null);
        navigate(`/view-property/${formData.propertyId}`);
      }, 3000); // Wait for 3 seconds before redirecting
    } catch (error) {
      setSubmitStatus({ success: false, message: "Failed to create store." });
    }

    setLoading(false);
  };

  return (
    <>
      <DashboardHeader hostData={props.hostData} />
      <div className="dashboard">
        <div className="dashboard__section">
          <Container>
            <Box sx={{ mt: 4, mb: 3 }}>
              <Typography variant="h4" sx={{ color: "#000", mb: 2 }}>
                Create Your Rental Store
              </Typography>
            </Box>
            <form
              onSubmit={handleSubmit}
              style={{ width: "90%", maxWidth: "800px" }}
            >
              {/* Property Location Section */}
              <Typography variant="h6" sx={{ mt: 2 }}>
                Property Location
              </Typography>

              {/* Dropdown for City and Country */}
              <FormControl fullWidth margin="normal" required>
                <InputLabel id="location-label">City and Country</InputLabel>
                <Select
                  labelId="location-label"
                  name="location"
                  value={`${formData.city} - ${formData.country}`}
                  onChange={handleLocationChange}
                  label="City and Country"
                  sx={inputStyles}
                >
                  <MenuItem value="Vienna - Austria">Vienna - Austria</MenuItem>
                  <MenuItem value="Budapest - Hungary">
                    Budapest - Hungary
                  </MenuItem>
                </Select>
              </FormControl>

              <TextField
                label="Address"
                name="address"
                fullWidth
                value={formData.address}
                onChange={handleInputChange}
                margin="normal"
                required
                sx={inputStyles}
              />

              {/* Information for Guest Store */}
              <Box sx={{ mt: 4, mb: 2 }}>
                <Typography variant="h6" sx={{ color: "#f89646" }}>
                  Information for Guest Store
                </Typography>
                <Typography variant="body2" sx={{ color: "#6bb7be" }}>
                  The following inputs collect information that will be visible
                  to guests in your property store.
                </Typography>
              </Box>

              {/* Apartment Details Section */}
              <Typography variant="h6" sx={{ mt: 2 }}>
                Apartment Details
              </Typography>
              <TextField
                label="Exact Apartment Address"
                name="exactAddress"
                fullWidth
                value={formData.exactAddress}
                onChange={handleInputChange}
                margin="normal"
                multiline
                rows={3}
                sx={inputStyles}
              />
              <TextField
                label="Wi-Fi Information"
                name="wifiInfo"
                fullWidth
                value={formData.wifiInfo}
                onChange={handleInputChange}
                margin="normal"
                multiline
                rows={2}
                sx={inputStyles}
              />
              <TextField
                label="Apartment Size & Layout"
                name="apartmentLayout"
                fullWidth
                value={formData.apartmentLayout}
                onChange={handleInputChange}
                margin="normal"
                multiline
                rows={3}
                sx={inputStyles}
              />
              <TextField
                label="Check-in/Check-out Times"
                name="checkinCheckout"
                fullWidth
                value={formData.checkinCheckout}
                onChange={handleInputChange}
                margin="normal"
                multiline
                rows={2}
                sx={inputStyles}
              />
              <TextField
                label="Emergency Contacts"
                name="emergencyContacts"
                fullWidth
                value={formData.emergencyContacts}
                onChange={handleInputChange}
                margin="normal"
                multiline
                rows={3}
                sx={inputStyles}
              />

              {/* House Rules Section */}
              <Typography variant="h6" sx={{ mt: 2 }}>
                House Rules
              </Typography>
              <TextField
                label="Noise Policy"
                name="noisePolicy"
                fullWidth
                value={formData.noisePolicy}
                onChange={handleInputChange}
                margin="normal"
                multiline
                rows={2}
                sx={inputStyles}
              />
              <TextField
                label="Smoking Policy"
                name="smokingPolicy"
                fullWidth
                value={formData.smokingPolicy}
                onChange={handleInputChange}
                margin="normal"
                multiline
                rows={2}
                sx={inputStyles}
              />
              <TextField
                label="Pet Policy"
                name="petPolicy"
                fullWidth
                value={formData.petPolicy}
                onChange={handleInputChange}
                margin="normal"
                multiline
                rows={2}
                sx={inputStyles}
              />
              <TextField
                label="Trash & Recycling"
                name="trashRecycling"
                fullWidth
                value={formData.trashRecycling}
                onChange={handleInputChange}
                margin="normal"
                multiline
                rows={2}
                sx={inputStyles}
              />
              <TextField
                label="Key Access & Locking Instructions"
                name="keyAccess"
                fullWidth
                value={formData.keyAccess}
                onChange={handleInputChange}
                margin="normal"
                multiline
                rows={2}
                sx={inputStyles}
              />
              <TextField
                label="Appliance Instructions"
                name="applianceInstructions"
                fullWidth
                value={formData.applianceInstructions}
                onChange={handleInputChange}
                margin="normal"
                multiline
                rows={3}
                sx={inputStyles}
              />

              {/* Local Amenities Section */}
              <Typography variant="h6" sx={{ mt: 2 }}>
                Local Amenities
              </Typography>
              <TextField
                label="Nearest Grocery Stores"
                name="groceryStores"
                fullWidth
                value={formData.groceryStores}
                onChange={handleInputChange}
                margin="normal"
                multiline
                rows={2}
                sx={inputStyles}
              />
              <TextField
                label="Pharmacy Locations"
                name="pharmacyLocations"
                fullWidth
                value={formData.pharmacyLocations}
                onChange={handleInputChange}
                margin="normal"
                multiline
                rows={2}
                sx={inputStyles}
              />
              <TextField
                label="Local Restaurants"
                name="localRestaurants"
                fullWidth
                value={formData.localRestaurants}
                onChange={handleInputChange}
                margin="normal"
                multiline
                rows={2}
                sx={inputStyles}
              />
              <TextField
                label="ATM & Currency Exchange"
                name="atmCurrency"
                fullWidth
                value={formData.atmCurrency}
                onChange={handleInputChange}
                margin="normal"
                multiline
                rows={2}
                sx={inputStyles}
              />
              <TextField
                label="Public Transportation"
                name="publicTransport"
                fullWidth
                value={formData.publicTransport}
                onChange={handleInputChange}
                margin="normal"
                multiline
                rows={2}
                sx={inputStyles}
              />

              {/* Guest Services Section */}
              <Typography variant="h6" sx={{ mt: 2 }}>
                Guest Services
              </Typography>
              <TextField
                label="Taxi & Ride Services"
                name="taxiServices"
                fullWidth
                value={formData.taxiServices}
                onChange={handleInputChange}
                margin="normal"
                multiline
                rows={2}
                sx={inputStyles}
              />
              <TextField
                label="Laundry Services"
                name="laundryServices"
                fullWidth
                value={formData.laundryServices}
                onChange={handleInputChange}
                margin="normal"
                multiline
                rows={2}
                sx={inputStyles}
              />
              <TextField
                label="Grocery Delivery"
                name="groceryDelivery"
                fullWidth
                value={formData.groceryDelivery}
                onChange={handleInputChange}
                margin="normal"
                multiline
                rows={2}
                sx={inputStyles}
              />
              <TextField
                label="Emergency Numbers"
                name="emergencyNumbers"
                fullWidth
                value={formData.emergencyNumbers}
                onChange={handleInputChange}
                margin="normal"
                multiline
                rows={2}
                sx={inputStyles}
              />

              {/* Neighborhood Information Section */}
              <Typography variant="h6" sx={{ mt: 2 }}>
                Neighborhood Information
              </Typography>
              <TextField
                label="Local Attractions"
                name="localAttractions"
                fullWidth
                value={formData.localAttractions}
                onChange={handleInputChange}
                margin="normal"
                multiline
                rows={2}
                sx={inputStyles}
              />
              <TextField
                label="Parks & Outdoor Spaces"
                name="parks"
                fullWidth
                value={formData.parks}
                onChange={handleInputChange}
                margin="normal"
                multiline
                rows={2}
                sx={inputStyles}
              />
              <TextField
                label="Gyms or Fitness Centers"
                name="gyms"
                fullWidth
                value={formData.gyms}
                onChange={handleInputChange}
                margin="normal"
                multiline
                rows={2}
                sx={inputStyles}
              />

              {/* Safety & Security Section */}
              <Typography variant="h6" sx={{ mt: 2 }}>
                Safety & Security
              </Typography>
              <TextField
                label="Building Security"
                name="buildingSecurity"
                fullWidth
                value={formData.buildingSecurity}
                onChange={handleInputChange}
                margin="normal"
                multiline
                rows={2}
                sx={inputStyles}
              />
              <TextField
                label="Fire Escape Plan"
                name="fireEscapePlan"
                fullWidth
                value={formData.fireEscapePlan}
                onChange={handleInputChange}
                margin="normal"
                multiline
                rows={2}
                sx={inputStyles}
              />

              {/* Checkout Instructions Section */}
              <Typography variant="h6" sx={{ mt: 2 }}>
                Checkout Instructions
              </Typography>
              <TextField
                label="Check-Out Procedure"
                name="checkOutProcedure"
                fullWidth
                value={formData.checkOutProcedure}
                onChange={handleInputChange}
                margin="normal"
                multiline
                rows={2}
                sx={inputStyles}
              />
              <TextField
                label="Deposit Refund"
                name="depositRefund"
                fullWidth
                value={formData.depositRefund}
                onChange={handleInputChange}
                margin="normal"
                multiline
                rows={2}
                sx={inputStyles}
              />
              <TextField
                label="Review Request"
                name="reviewRequest"
                fullWidth
                value={formData.reviewRequest}
                onChange={handleInputChange}
                margin="normal"
                multiline
                rows={2}
                sx={inputStyles}
              />

              {/* Submit Button with Loading Indicator */}
              <Box
                sx={{ textAlign: "center", mt: 3, mb: 3, maxWidth: "260px" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={loading}
                  fullWidth
                  sx={{ backgroundColor: "#f89646", color: "#fff" }}
                >
                  {loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Launch Store"
                  )}
                </Button>

                {submitStatus && (
                  <Typography
                    variant="body2"
                    color={submitStatus.success ? "green" : "red"}
                    sx={{ mt: 2 }}
                  >
                    {submitStatus.message}
                  </Typography>
                )}
              </Box>
            </form>
          </Container>
        </div>
      </div>
    </>
  );
}

export default DashboardCreateProperty;

// Styles for input fields
const inputStyles = {
  backgroundColor: "#f7fafa",
  borderRadius: "5px",
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#6bb7be", // Secondary focus border color
    },
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#6bb7be", // Secondary focus label color
  },
};
