import React, { useState, useRef } from "react";
import axios from "axios";
import "./index.css";
import { useAuth0 } from "@auth0/auth0-react";

// Import components
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import EarningsCalculator from "../../components/EarningsCalculator";

// Import images
import HeroImg from "../../img/hero_img_3.webp";
import HeroImg2 from "../../img/hero_img_2.webp";
import Section2Img1 from "../../img/section2_1.webp";
import Section2Img2 from "../../img/section2_2.webp";
import Section2Img3 from "../../img/section2_3.webp";
import Section3Img from "../../img/section3_2.webp";
import Section5Img1 from "../../img/section5_1.webp";
import Section5Img2 from "../../img/section5_2.webp";
import Section5Img3 from "../../img/section5_3.webp";
import StoreSupermarketImg from "../../img/store_welcome_supermarket_slide_2.webp";

function HostersInfo() {
  const { loginWithRedirect } = useAuth0();
  // START SCROLL FUNC GPT
  // Create a reference for the target section
  const formSectionRef = useRef(null);
  const howItWorksSectionRef = useRef(null);

  // Function to handle the smooth scroll
  const scrollToSection = (sectionRef) => {
    sectionRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start", // Optional: can be 'center', 'end', etc.
    });
  };
  // END SCROLL FUNC GPT

  // START HANDLING FORM GPT
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    city: "",
    overview: "",
  });

  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false); // Track loading state
  const [buttonDisabled, setButtonDisabled] = useState(false); // Disable button state

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Show loading indicator
    setButtonDisabled(true); // Disable submit button

    const currentDate = new Date().toLocaleDateString(); // Get current date

    try {
      const response = await axios.post(
        process.env.REACT_APP_SHEET_BEST_API_URL, // Replace with your Sheet.best endpoint
        {
          ...formData,
          date: currentDate, // Add the date of submission
        }
      );
      if (response.status === 200) {
        setMessage("Form successfully sent!");
        setFormData({
          firstName: "",
          lastName: "",
          phone: "",
          email: "",
          city: "",
          overview: "",
        });
        setTimeout(() => setMessage(null), 5000); // Clear success message after 5 seconds
      }
    } catch (error) {
      setMessage("There was an error sending the form. Please try again.");
      setTimeout(() => setMessage(null), 5000); // Clear error message after 5 seconds
    } finally {
      setLoading(false); // Stop loading indicator
      setButtonDisabled(false); // Re-enable button
    }
  };
  // END HANDLING FORM GPT

  return (
    <>
      <Header refSection={formSectionRef} />
      <div className="page">
        {/* SECTION 1 - Hero */}
        <div className="section hero">
          <div className="hero__text">
            <h1 className="hero__title">
              <span>Effortless Earnings for Airbnb Hosts</span>
            </h1>
            <p className="hero__paragraph">
              Imagine earning extra income, completely passively, while
              enhancing your guest experience. Hoster Store is designed to help
              you do just that by offering your guests curated products and
              services that they need during their stay.
            </p>

            <div className="hero__btns">
              <button
                className="hero__btn hero__btn1"
                //onClick={() => scrollToSection(formSectionRef)}
                onClick={() => loginWithRedirect()}
              >
                {" "}
                SignUp / Login
              </button>
              <button
                href="#"
                className="hero__btn hero__btn2"
                onClick={() => scrollToSection(howItWorksSectionRef)}
              >
                {" "}
                How It Works
              </button>
            </div>
          </div>
          <div className="hero__img">
            <img src={StoreSupermarketImg} />
          </div>
        </div>

        {/* SECTION 2 - Why join Hoster Store */}
        <div className="section section2">
          <h1 className="section2__title">
            <span>Why join Hoster Store</span>
          </h1>
          <div className="section2__boxes">
            <div className="section2__box">
              <div className="section2__box-img">
                <img src={Section2Img1} />
              </div>
              <div className="section2__box-text">
                <h5 className="section2__box-title">
                  Effortless Passive Income
                </h5>
                <p className="section2__box-paragraph">
                  Your guests shop, you earn. With Hoster Store, you earn a
                  share of the profit from every purchase your guests
                  make—completely passively. No need to manage inventory or
                  services; we handle everything for you.
                </p>
              </div>
            </div>
            <div className="section2__box">
              <div className="section2__box-img">
                <img src={Section2Img2} />
              </div>
              <div className="section2__box-text">
                <h5 className="section2__box-title">
                  Enhance Guest Experience
                </h5>
                <p className="section2__box-paragraph">
                  Make their stay unforgettable. By offering curated local
                  experiences and services, you give your guests added value
                  that goes beyond just accommodation. Happy guests mean better
                  reviews and repeat bookings.
                </p>
              </div>
            </div>
            <div className="section2__box">
              <div className="section2__box-img">
                <img src={Section2Img3} />
              </div>
              <div className="section2__box-text">
                <h5 className="section2__box-title">Free, Easy Setup</h5>
                <p className="section2__box-paragraph">
                  Zero cost to join. It’s free to sign up, and we provide you
                  with all the marketing materials, including a personalized web
                  shop and a brochure with a QR code for your apartment.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Earnings calculator */}
        <EarningsCalculator />

        {/* SECTION 3 - How it works */}
        <div ref={howItWorksSectionRef} className="section hero section3">
          <div className="hero__text">
            <h1 className="hero__title">
              <span>Here’s How It Works</span>
            </h1>
            <p className="hero__paragraph">
              <ol>
                <li>
                  <h5>Sign Up and Get Started Right Away</h5>
                  <span>
                    After signing up, you’ll have immediate access to your
                    Hoster Store dashboard. Simply add your rental property
                    details and guest information—no need to customize services,
                    as everything is ready to go. Once completed, you’ll receive
                    a unique web shop link that you can share with your guests
                    immediately.
                  </span>
                </li>
                <li>
                  <h5>Your Ready-to-Go Web Shop</h5>
                  <span>
                    We provide a fully built shop with a curated selection of
                    services that your guests will love, from local supermarket
                    essentials to tours and transportation options. Your shop is
                    tailored to your property’s location, offering guests all
                    the services they need, while you earn extra revenue.
                  </span>
                </li>
                <li>
                  <h5>Equal Partnership – 50/50 Split</h5>
                  <span>
                    Every purchase your guests make through your web shop
                    generates revenue. You’ll receive 50% of the profits, and as
                    we continue adding more services, your earnings will
                    increase, providing you with passive income from each
                    rental.
                  </span>
                </li>
                <li>
                  <h5>Effortless Guest Experience</h5>
                  <span>
                    Guests can access your personalized shop via the link you
                    provide, where they can book services directly from their
                    phones. The shop is pre-filled with everything they need,
                    and you can enhance their experience by including
                    property-specific information like house rules and welcome
                    messages. Additionally, we offer the option to send a
                    beautifully designed flyer or brochure to your apartment,
                    complete with a QR code, making it even easier for your
                    guests to access your shop during their stay.
                  </span>
                </li>
              </ol>
            </p>
          </div>
          <div className="hero__img">
            <img src={Section3Img} />
          </div>
        </div>

        {/* SECTION 4 - More benefits */}
        <div className="section hero section4">
          <div className="hero__text">
            <h1 className="hero__title">
              <span>More Benefits</span>
            </h1>
            <p className="hero__paragraph">
              <ul>
                <li>
                  <h5>Completely Free to Join</h5>
                  <div>
                    There are no setup fees, hidden charges, or ongoing costs.
                    You earn purely through the commissions generated by guest
                    purchases.
                  </div>
                </li>
                <li>
                  <h5>50/50 Profit Sharing</h5>
                  <div>
                    As equal partners, we both benefit equally from every
                    purchase your guests make. You get 50%, and we get 50%. It’s
                    that simple.
                  </div>
                </li>
                <li>
                  <h5>We Handle Everything</h5>
                  <div>
                    We take care of sourcing products, managing orders, and
                    ensuring deliveries. You don’t have to lift a finger – just
                    sit back and earn.
                  </div>
                </li>
                <li>
                  <h5>Professional Marketing Materials</h5>
                  <div>
                    We will send you a flyer or brochure featuring a QR code
                    that links directly to your branded shop. You can easily
                    display this in your apartment, giving guests instant access
                    to your web shop.
                  </div>
                </li>
                <li>
                  <h5>The Potential for Growth</h5>
                  <div>
                    The more guests you host, the more potential income you can
                    earn. As Hoster Store grows, we will continue to add more
                    products and services, increasing your earning potential
                    without limits. This long-term partnership is designed to
                    scale over time, ensuring that as we expand, your
                    opportunities to earn will grow alongside us.
                  </div>
                </li>
              </ul>
            </p>
          </div>
          <div className="hero__img">
            <img src={HeroImg2} />
          </div>
        </div>

        {/* SECTION 5 - How Do You Get Started? */}
        <div className="section section2">
          <h1 className="section2__title">
            <span>How Do You Get Started?</span>
          </h1>
          <div className="section2__boxes">
            <div className="section2__box">
              <div className="section2__box-img">
                <img src={Section5Img1} />
              </div>
              <div className="section2__box-text">
                <h5 className="section2__box-title">
                  1. Sign Up and Launch Your Store
                </h5>
                <p className="section2__box-paragraph">
                  Create your account on Hoster Store by completing a quick
                  registration process. Once signed up, you can immediately
                  access your host dashboard to create and customize your rental
                  store. From the dashboard, you can start offering curated
                  products and services to your guests in just a few clicks,
                  earning commissions right away!
                </p>
              </div>
            </div>
            <div className="section2__box">
              <div className="section2__box-img">
                <img src={Section5Img2} />
              </div>
              <div className="section2__box-text">
                <h5 className="section2__box-title">2. Promote Your Shop</h5>
                <p className="section2__box-paragraph">
                  Use our free brochure or flyer with a QR code to share your
                  shop with your guests. Place it in your apartment or include
                  the link in your Airbnb messages.
                </p>
              </div>
            </div>
            <div className="section2__box">
              <div className="section2__box-img">
                <img src={Section5Img3} />
              </div>
              <div className="section2__box-text">
                <h5 className="section2__box-title">3. Start Earning</h5>
                <p className="section2__box-paragraph">
                  Every time a guest makes a purchase, you’ll earn a commission,
                  with no extra effort required from your side.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* SECTION 6 - Host Registration Form */}
        {/*
         <div ref={formSectionRef} className="section hero section6">
          <div className="hero__text">
            <h1 className="hero__title">
              <span>No cost, no risk - Partner with Hoster Store today!</span>
            </h1>
            <form className="section6__inputs" onSubmit={handleSubmit}>
              <div className="section6__input-wrapper">
                <h5>Your First Name</h5>
                <input
                  type="text"
                  name="firstName"
                  placeholder="Your First Name"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />

                <h5>Your Last Name</h5>
                <input
                  type="text"
                  name="lastName"
                  placeholder="Your Last Name"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                />

                <h5>Contact Phone Number</h5>
                <input
                  type="tel"
                  name="phone"
                  placeholder="Your Phone Number"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />

                <h5>Email Address</h5>
                <input
                  type="email"
                  name="email"
                  placeholder="Your Email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />

                <h5>City Where You Host</h5>
                <input
                  type="text"
                  name="city"
                  placeholder="Your City"
                  value={formData.city}
                  onChange={handleChange}
                  required
                />

                <h5>Tell Us About Your Properties</h5>
                <textarea
                  rows="4"
                  name="overview"
                  placeholder="How many apartments do you manage? Apartment size and average number of guests you host."
                  value={formData.overview}
                  onChange={handleChange}
                  required
                ></textarea>

                <button type="submit" disabled={buttonDisabled}>
                  {loading ? "Sending..." : "Send"}
                </button>
              </div>
            </form>

            {message && <p>{message}</p>}
          </div>
          <div className="hero__img">
            <img src={Section3Img} alt="Hoster Store" />
          </div>
        </div>
        */}
      </div>
      <Footer />
    </>
  );
}
export default HostersInfo;
