import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  Avatar,
  CircularProgress,
} from "@mui/material";
import DashboardHeader from "../../dashboard-components/DashboardHeader";
import axios from "axios";

function DashboardHostProfile(props) {
  const [formData, setFormData] = useState({
    fullName: "",
    whatsappNumber: "",
    officeAddress: "",
    profileImageUrl: null, // Image field
    hostId: "", // Add hostId to the form data
  });

  const [previewImage, setPreviewImage] = useState(null); // State to store preview of new image
  const [submitStatus, setSubmitStatus] = useState(null);
  const [loading, setLoading] = useState(false); // Loading state

  // Load the host data into the form when the component loads
  useEffect(() => {
    if (props.hostData) {
      setFormData({
        fullName: props.hostData.fullName,
        whatsappNumber: props.hostData.whatsappNumber,
        officeAddress: props.hostData.officeAddress,
        profileImageUrl: props.hostData.profileImageUrl, // Store the image URL if it exists
        hostId: props.hostData.hostId, // Store the hostId from props
      });
    }
  }, [props.hostData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({
        ...formData,
        profileImageUrl: file, // Storing the new image file
      });
      setPreviewImage(URL.createObjectURL(file)); // Setting preview of the new image
    } else {
      console.error("Invalid file provided for image upload.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Show loading state
    const dataToSend = new FormData();
    dataToSend.append("hostId", formData.hostId); // Include the hostId for identifying the row
    dataToSend.append("fullName", formData.fullName);
    dataToSend.append("whatsappNumber", formData.whatsappNumber);
    dataToSend.append("officeAddress", formData.officeAddress);
    if (formData.profileImageUrl) {
      dataToSend.append("profileImageUrl", formData.profileImageUrl);
    }

    try {
      /*
      const response = await axios.patch(
        `http://localhost:5000/api/update-host`, 
        dataToSend
      );
      */
      const response = await axios.patch(
        `https://hoster-store-439319.ew.r.appspot.com/api/update-host`,
        dataToSend
      );
      setSubmitStatus({
        success: true,
        message: "Profile updated successfully!",
      });
    } catch (error) {
      console.error("Error updating profile:", error);
      setSubmitStatus({ success: false, message: "Failed to update profile" });
    } finally {
      setLoading(false); // Hide loading state
      setTimeout(() => {
        setSubmitStatus(null); // Hide success/fail message after 5 seconds
      }, 5000);
    }
  };

  return (
    <>
      <DashboardHeader hostData={props.hostData} />
      <div className="dashboard">
        <div className="dashboard__section">
          <Container>
            <Box sx={{ mt: 4 }}>
              <Typography variant="h5" component="h1" gutterBottom>
                Your Host Profile
              </Typography>
            </Box>

            {/* Display the profile image if it exists */}
            <Box sx={{ mb: 3 }}>
              <Avatar
                alt="Host Profile"
                src={previewImage || formData.profileImageUrl} // Display preview if new image is uploaded, else show existing image
                sx={{ width: 100, height: 100, mb: 2 }}
              />
            </Box>

            {/* Display host id */}
            <Box sx={{ mb: 3 }}>
              <Typography variant="body2" sx={{ mt: 2, mb: 1 }}>
                Host ID: {props.hostData && props.hostData.hostId}
              </Typography>
            </Box>

            <form onSubmit={handleSubmit} style={{ maxWidth: "70%" }}>
              <TextField
                label="Full Name"
                name="fullName"
                fullWidth
                value={formData.fullName}
                onChange={handleInputChange}
                margin="normal"
                required
              />

              <TextField
                label="WhatsApp Number"
                name="whatsappNumber"
                fullWidth
                value={formData.whatsappNumber}
                onChange={handleInputChange}
                margin="normal"
                required
              />

              <TextField
                label="Office Address"
                name="officeAddress"
                fullWidth
                value={formData.officeAddress}
                onChange={handleInputChange}
                margin="normal"
                required
              />

              <Typography variant="body2" sx={{ mt: 2, mb: 1 }}>
                Upload a profile image (optional)
              </Typography>
              <input type="file" onChange={handleImageChange} />

              <Box sx={{ mt: 2, mb: 2 }}>
                {/* Show loading indicator or button */}
                {loading ? (
                  <CircularProgress />
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    fullWidth
                    sx={{ backgroundColor: "#f89646", color: "#fff" }}
                    disabled={loading}
                  >
                    Update Host Profile
                  </Button>
                )}
              </Box>

              {submitStatus && (
                <Typography
                  variant="body2"
                  color={submitStatus.success ? "green" : "red"}
                  sx={{ mt: 2 }}
                >
                  {submitStatus.message}
                </Typography>
              )}
            </form>
          </Container>
        </div>
      </div>
    </>
  );
}

export default DashboardHostProfile;
