import "./index.css";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";

// Import components
import StoreHeader from "../../store-components/StoreHeader";

// Import MUI
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

function StoreExperiences(props) {
  const { hostId, propertyId } = useParams();
  const [hostData, setHostData] = useState(null);
  const [propertyData, setPropertyData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchHostData = async () => {
      try {
        // const response = await axios.get("http://localhost:5000/api/hosts");
        const response = await axios.get(
          "https://hoster-store-439319.ew.r.appspot.com/api/hosts"
        );
        const hosts = response.data;
        const host = hosts.find((h) => h.hostId === hostId);
        if (host) {
          setHostData(host);
        } else {
          setError("Host not found");
        }
      } catch (err) {
        setError("Failed to fetch host data.");
      }
    };

    const fetchPropertyData = async () => {
      try {
        // const response = await axios.get(`http://localhost:5000/api/property/${propertyId}`);
        const response = await axios.get(
          `https://hoster-store-439319.ew.r.appspot.com/api/property/${propertyId}`
        );
        setPropertyData(response.data);
      } catch (err) {
        setError("Failed to fetch property data.");
      }
    };

    Promise.all([fetchHostData(), fetchPropertyData()])
      .then(() => setLoading(false))
      .catch((err) => {
        setError("Failed to load data.");
        setLoading(false);
      });
  }, [hostId, propertyId]);

  return (
    <>
      <StoreHeader />
      <div className="store store-experiences">
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {error ? (
              <div>{error}</div>
            ) : (
              <div className="store__section store-experiences__hero">
                <h1 className="store-experiences__title">
                  Discover Unforgettable {propertyData?.city} Tours and
                  Activities!
                </h1>
                <div className="store-experiences__getyourguide-activities">
                  <div
                    data-gyg-href="https://widget.getyourguide.com/default/activities.frame"
                    data-gyg-location-id={
                      propertyData?.city === "Budapest"
                        ? "29"
                        : propertyData?.city === "Vienna"
                        ? "7"
                        : ""
                    }
                    data-gyg-locale-code="en-US"
                    data-gyg-widget="activities"
                    data-gyg-number-of-items="44"
                    data-gyg-partner-id="4923WON"
                    data-gyg-cmp={hostData && hostData.hostId}
                  ></div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default StoreExperiences;
