import "./index.css";

function Footer() {
  return (
    <div className="footer">
      <div className="footer__content">
        <span>© 2024 Hoster Store. All rights reserved.</span>
      </div>
    </div>
  );
}
export default Footer;
