import React, { useState } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  Avatar,
} from "@mui/material";
import { PhotoCamera } from "@mui/icons-material";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom"; // Import for redirection

// Import the logo
import HosterStoreLogo from "../../img/logo-no-background.png";

function DashboardHostSignUpForm() {
  const { user } = useAuth0();
  const navigate = useNavigate(); // To handle redirection

  const [formData, setFormData] = useState({
    fullName: "",
    whatsappNumber: "",
    officeAddress: "",
    profileImageUrl: null, // Correct field name
    email: user.email,
  });

  const [submitStatus, setSubmitStatus] = useState(null); // For handling success/error messages
  const [loading, setLoading] = useState(false); // Manage form submission state

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e) => {
    setFormData({ ...formData, profileImageUrl: e.target.files[0] }); // Use the correct field name here
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Disable button during submission
    setSubmitStatus(null); // Reset message

    // Create a FormData object to handle text and file data
    const dataToSend = new FormData();
    dataToSend.append("fullName", formData.fullName);
    dataToSend.append("whatsappNumber", formData.whatsappNumber);
    dataToSend.append("officeAddress", formData.officeAddress);
    dataToSend.append("email", formData.email);
    if (formData.profileImageUrl) {
      dataToSend.append("profileImageUrl", formData.profileImageUrl); // Append the image if exists
    }

    try {
      // Send POST request to backend
      /*
      const response = await axios.post(
        "http://localhost:5000/api/register-host",
        dataToSend
      );
      */
      const response = await axios.post(
        "https://hoster-store-439319.ew.r.appspot.com/api/register-host",
        dataToSend
      );

      setSubmitStatus({ success: true, message: response.data.message });
      setTimeout(() => {
        //navigate("/"); // Redirect after a delay
        window.location.reload();
      }, 3000);
    } catch (error) {
      console.error("There was an error registering the host!", error);
      setSubmitStatus({ success: false, message: "Failed to register host" });
    }

    setLoading(false); // Re-enable button after submission
  };

  return (
    <Container maxWidth="sm" style={{ marginBottom: "20px" }}>
      {/* Logo at the top */}
      <Box sx={{ textAlign: "center", mt: 4 }}>
        <img
          src={HosterStoreLogo}
          alt="Hoster Store Logo"
          style={{ width: "150px" }}
        />
      </Box>

      {/* Page Title */}
      <Box sx={{ mt: 4, textAlign: "center" }}>
        <Typography variant="h5" component="h1" gutterBottom>
          Complete Your Profile
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Please fill out the information below to help us create your
          personalized welcome page for guests.
        </Typography>
      </Box>

      <form onSubmit={handleSubmit}>
        {/* Full Name */}
        <Typography variant="body2" color="textSecondary" sx={{ mt: 2, mb: 0 }}>
          Your full name will be displayed on the welcome page to greet your
          guests personally.
        </Typography>
        <TextField
          label="Full Name"
          name="fullName"
          fullWidth
          value={formData.fullName}
          onChange={handleInputChange}
          margin="normal"
          required
          sx={{
            backgroundColor: "#f7fafa",
            borderRadius: "5px",
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                borderColor: "#6bb7be", // Custom focus border color
              },
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: "#6bb7be", // Custom focus label color
            },
          }}
        />

        {/* WhatsApp Number */}
        <Typography variant="body2" color="textSecondary" sx={{ mt: 2, mb: 0 }}>
          Guests may use your WhatsApp number to contact you directly for any
          questions or assistance.
        </Typography>
        <TextField
          label="WhatsApp Number"
          name="whatsappNumber"
          fullWidth
          value={formData.whatsappNumber}
          onChange={handleInputChange}
          margin="normal"
          required
          sx={{
            backgroundColor: "#f7fafa",
            borderRadius: "5px",
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                borderColor: "#6bb7be", // Custom focus border color
              },
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: "#6bb7be", // Custom focus label color
            },
          }}
        />

        {/* Address */}
        <Typography variant="body2" color="textSecondary" sx={{ mt: 2, mb: 0 }}>
          We need your address to send marketing materials such as brochures and
          flyers.
        </Typography>
        <TextField
          label="Address"
          name="officeAddress"
          fullWidth
          value={formData.officeAddress}
          onChange={handleInputChange}
          margin="normal"
          required
          sx={{
            backgroundColor: "#f7fafa",
            borderRadius: "5px",
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                borderColor: "#6bb7be", // Custom focus border color
              },
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: "#6bb7be", // Custom focus label color
            },
          }}
        />

        {/* Profile Image Upload */}
        <Typography variant="body2" color="textSecondary" sx={{ mt: 2, mb: 0 }}>
          Upload a profile image (optional) to give a more personal feel to your
          guests. This helps create trust and connection.
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", mt: 2, mb: 3 }}>
          <Avatar
            src={
              formData.profileImageUrl
                ? URL.createObjectURL(formData.profileImageUrl)
                : ""
            }
            sx={{ width: 100, height: 100, mr: 2 }}
          />
          <Button
            variant="contained"
            component="label"
            startIcon={<PhotoCamera />}
            sx={{ backgroundColor: "#6bb7be" }}
          >
            Upload Profile Image
            <input
              type="file"
              hidden
              accept="image/*"
              onChange={handleImageChange}
            />
          </Button>
        </Box>

        {/* Submit Button */}
        <Button
          variant="contained"
          color="primary"
          type="submit"
          fullWidth
          disabled={loading} // Disable button when loading
          sx={{ mt: 2, backgroundColor: "#f89646", color: "#fff" }}
        >
          {loading ? "Submitting..." : "Complete Registration"}
        </Button>

        {/* Success/Error Message */}
        {submitStatus && (
          <Typography
            variant="body2"
            color={submitStatus.success ? "green" : "red"}
            sx={{ mt: 2 }}
          >
            {submitStatus.message}
          </Typography>
        )}
      </form>
    </Container>
  );
}

export default DashboardHostSignUpForm;
