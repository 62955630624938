import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Box,
  CircularProgress,
  Divider,
} from "@mui/material";
import axios from "axios";
import DashboardHeader from "../../dashboard-components/DashboardHeader";

function DashboardIncomeOverview(props) {
  const [earnings, setEarnings] = useState([]);
  const [payments, setPayments] = useState([]);
  const [loadingEarnings, setLoadingEarnings] = useState(true);
  const [loadingPayments, setLoadingPayments] = useState(true);
  const [error, setError] = useState(null);

  const hostId = props.hostData.hostId; // Replace with dynamic hostId

  // Fetch earnings and payments data
  useEffect(() => {
    const fetchEarnings = async () => {
      try {
        /*
        const earningsResponse = await axios.get(
          `http://localhost:5000/api/earnings`
        );
        */
        const earningsResponse = await axios.get(
          `https://hoster-store-439319.ew.r.appspot.com/api/earnings`
        );
        const hostEarnings = earningsResponse.data.filter(
          (earning) => earning.hostId === hostId
        );
        setEarnings(hostEarnings);
        setLoadingEarnings(false);
      } catch (err) {
        setError("Error fetching earnings data.");
        setLoadingEarnings(false);
      }
    };

    const fetchPayments = async () => {
      try {
        /*
        const paymentsResponse = await axios.get(
          `http://localhost:5000/api/payments`
        );
        */
        const paymentsResponse = await axios.get(
          `https://hoster-store-439319.ew.r.appspot.com/api/payments`
        );
        const hostPayments = paymentsResponse.data.filter(
          (payment) => payment.hostId === hostId
        );
        setPayments(hostPayments);
        setLoadingPayments(false);
      } catch (err) {
        setError("Error fetching payments data.");
        setLoadingPayments(false);
      }
    };

    fetchEarnings();
    fetchPayments();
  }, [hostId]);

  const totalEarnings = earnings.reduce(
    (acc, item) => acc + parseFloat(item.hostEarnings || 0),
    0
  );
  const totalPayments = payments.reduce(
    (acc, item) => acc + parseFloat(item.paymentAmount || 0),
    0
  );
  const balance = totalEarnings - totalPayments;

  return (
    <>
      <DashboardHeader hostData={props.hostData} />
      <div className="dashboard">
        <div className="dashboard__section">
          <Container sx={{ mt: 4 }}>
            <Typography variant="h4" sx={{ color: "#000", mb: 2 }}>
              Income Overview
            </Typography>

            {/* Overall Summary */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
                backgroundColor: "#f7fafa",
                padding: "16px",
                borderRadius: "8px",
                mb: 4,
              }}
            >
              <Typography variant="h6" sx={{ color: "#f89646", mb: 2 }}>
                Total Earnings: €{totalEarnings.toFixed(2)}
              </Typography>
              <Typography variant="h6" sx={{ color: "#6bb7be", mb: 2 }}>
                Total Payments: €{totalPayments.toFixed(2)}
              </Typography>
              <Typography
                variant="h6"
                sx={{ color: balance >= 0 ? "#4caf50" : "#f44336", mb: 2 }}
              >
                Current Balance: €{balance.toFixed(2)}
              </Typography>
            </Box>

            {/* Earnings Section */}
            <Typography variant="h5" sx={{ color: "#000", mb: 2 }}>
              Earnings Breakdown
            </Typography>
            {loadingEarnings ? (
              <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                <CircularProgress />
              </Box>
            ) : (
              <Grid container spacing={3}>
                {earnings.map((earning) => (
                  <Grid item xs={12} sm={6} md={4} key={earning.earningId}>
                    <Card sx={{ backgroundColor: "#f7fafa" }}>
                      <CardContent>
                        <Typography variant="h6" sx={{ color: "#f89646" }}>
                          {earning.serviceName}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{ color: "#000", mb: 1 }}
                        >
                          <strong>Store ID:</strong> {earning.propertyId}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{ color: "#000", mb: 1 }}
                        >
                          <strong>Service Type:</strong> {earning.serviceType}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{ color: "#000", mb: 1 }}
                        >
                          <strong>Date:</strong> {earning.transactionDate}
                        </Typography>

                        <Typography
                          variant="body2"
                          sx={{ color: "#000", mb: 1 }}
                        >
                          <strong>You Earn:</strong> €{earning.hostEarnings}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            )}

            <Divider sx={{ my: 4 }} />

            {/* Payments Section */}
            <Typography variant="h5" sx={{ color: "#000", mb: 2 }}>
              Payment History
            </Typography>
            {loadingPayments ? (
              <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                <CircularProgress />
              </Box>
            ) : (
              <Grid container spacing={3}>
                {payments.map((payment) => (
                  <Grid item xs={12} sm={6} md={4} key={payment.paymentId}>
                    <Card sx={{ backgroundColor: "#f7fafa" }}>
                      <CardContent>
                        <Typography variant="h6" sx={{ color: "#f89646" }}>
                          Payment on {payment.paymentDate}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{ color: "#000", mb: 1 }}
                        >
                          <strong>Amount:</strong> €{payment.paymentAmount}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{ color: "#000", mb: 1 }}
                        >
                          <strong>Method:</strong> {payment.paymentMethod}
                        </Typography>
                        <Typography variant="body2" sx={{ color: "#6bb7be" }}>
                          <strong>Status:</strong> {payment.status}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            )}
          </Container>
        </div>
      </div>
    </>
  );
}

export default DashboardIncomeOverview;
