import "./index.css";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";

// Import components
import StoreHeader from "../../store-components/StoreHeader";

// Import images
import ServiceExperience from "../../img/store_welcome_experiences_slide.webp";
import ServiceTaxi from "../../img/store_welcome_taxi_slide.webp";

// Import MUI
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

function StoreWelcome() {
  const { hostId, propertyId } = useParams();
  const [hostData, setHostData] = useState(null);
  const [propertyData, setPropertyData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchHostData = async () => {
      try {
        //const response = await axios.get("http://localhost:5000/api/hosts");
        const response = await axios.get(
          "https://hoster-store-439319.ew.r.appspot.com/api/hosts"
        );
        const hosts = response.data;
        const host = hosts.find((h) => h.hostId === hostId);
        if (host) {
          setHostData(host);
        } else {
          setError("Host not found");
        }
      } catch (err) {
        setError("Failed to fetch host data.");
      }
    };

    const fetchPropertyData = async () => {
      try {
        /*
        const response = await axios.get(
          `http://localhost:5000/api/property/${propertyId}`
        );
        */
        const response = await axios.get(
          `https://hoster-store-439319.ew.r.appspot.com/api/property/${propertyId}`
        );
        setPropertyData(response.data);
      } catch (err) {
        setError("Failed to fetch property data.");
      }
    };

    Promise.all([fetchHostData(), fetchPropertyData()])
      .then(() => setLoading(false))
      .catch((err) => {
        setError("Failed to load data.");
        setLoading(false);
      });
  }, [hostId, propertyId]);

  const cityServices = {
    Vienna: [
      { name: "Experiences", path: "/experiences", img: ServiceExperience },
      {
        name: "Taxi Service",
        path: `https://www.book-online-transfers.com/en/hosterstore-airport-taxi?sub_affiliate_id=${propertyId}`,
        img: ServiceTaxi,
        external: true,
      },
    ],
    Budapest: [
      { name: "Experiences", path: "/experiences", img: ServiceExperience },
      {
        name: "Taxi Service",
        path: `https://www.book-online-transfers.com/en/hosterstore-airport-taxi?sub_affiliate_id=${propertyId}`,
        img: ServiceTaxi,
        external: true,
      },
    ],
  };

  const services = cityServices[propertyData?.city] || [];

  return (
    <>
      <StoreHeader />
      <div className="store store-welcome">
        <div className="store__section store-welcome__hero">
          {loading ? (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              {error ? (
                <div>{error}</div>
              ) : (
                <>
                  <div className="store-welcome__host-profile-wrapper">
                    <div className="store-welcome__host-profile-img">
                      <img
                        src={hostData?.profileImageUrl || "default-image.jpg"}
                        alt="Host"
                      />
                    </div>
                    <div className="store-welcome__host-name">
                      {hostData?.fullName || "Unknown Host"}
                    </div>
                    <h5 className="store-welcome__whatsapp">
                      WhatsApp:{" "}
                      <a
                        href={`https://wa.me/${hostData?.whatsappNumber}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {hostData?.whatsappNumber || "N/A"}
                      </a>
                    </h5>
                  </div>

                  <div className="store-welcome__content-wrapper">
                    <div className="store-welcome__welcoming-content">
                      <h1 className="store-welcome__title">
                        Welcome to Your {propertyData?.city} Home Away from
                        Home!
                      </h1>
                      <div className="store-welcome__services">
                        <h2 className="store-welcome__services-title">
                          Enhance Your Stay
                        </h2>
                        <div className="store-welcome__service-wrapper">
                          {services.map((service) => (
                            <a
                              key={service.name}
                              href={
                                service.external
                                  ? service.path
                                  : `/store/${hostId}/${propertyId}${service.path}`
                              }
                              target={service.external ? "_blank" : ""}
                              rel={
                                service.external ? "noopener noreferrer" : ""
                              }
                            >
                              <div className="store-welcome__service">
                                <div className="store-welcome__service-img">
                                  <img src={service.img} alt={service.name} />
                                </div>
                                <span className="store-welcome__service-title">
                                  {service.name}
                                </span>
                              </div>
                            </a>
                          ))}
                        </div>
                      </div>

                      <p className="store-welcome__paragraph">
                        I’m delighted to welcome you to my apartment! Whether
                        you’re here to explore {propertyData?.city}’s vibrant
                        culture or simply relax, I’ve made sure everything is
                        ready for you to enjoy your stay.
                      </p>
                    </div>

                    <div className="store-welcome__property-info">
                      <h2 className="store-welcome__property-info-title">
                        Important Information About Your Stay
                      </h2>

                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          Apartment Details
                        </AccordionSummary>
                        <AccordionDetails>
                          {propertyData?.address && (
                            <div className="store-welcome__property-info-section">
                              <h3>Address</h3>
                              <p>{propertyData.address}</p>
                            </div>
                          )}
                          {propertyData?.wifiInfo && (
                            <div className="store-welcome__property-info-section">
                              <h3>Wi-Fi Information</h3>
                              <p>{propertyData.wifiInfo}</p>
                            </div>
                          )}
                          {propertyData?.apartmentLayout && (
                            <div className="store-welcome__property-info-section">
                              <h3>Apartment Size & Layout</h3>
                              <p>{propertyData.apartmentLayout}</p>
                            </div>
                          )}
                          {propertyData?.checkinCheckout && (
                            <div className="store-welcome__property-info-section">
                              <h3>Check-in/Check-out Times</h3>
                              <p>{propertyData.checkinCheckout}</p>
                            </div>
                          )}
                          {propertyData?.emergencyContacts && (
                            <div className="store-welcome__property-info-section">
                              <h3>Emergency Contacts</h3>
                              <p>{propertyData.emergencyContacts}</p>
                            </div>
                          )}
                        </AccordionDetails>
                      </Accordion>

                      {/* House Rules Accordion */}
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          House Rules
                        </AccordionSummary>
                        <AccordionDetails>
                          {propertyData?.noisePolicy && (
                            <div className="store-welcome__property-info-section">
                              <h3>Noise Policy</h3>
                              <p>{propertyData.noisePolicy}</p>
                            </div>
                          )}
                          {propertyData?.smokingPolicy && (
                            <div className="store-welcome__property-info-section">
                              <h3>Smoking Policy</h3>
                              <p>{propertyData.smokingPolicy}</p>
                            </div>
                          )}
                          {propertyData?.petPolicy && (
                            <div className="store-welcome__property-info-section">
                              <h3>Pet Policy</h3>
                              <p>{propertyData.petPolicy}</p>
                            </div>
                          )}
                          {propertyData?.trashRecycling && (
                            <div className="store-welcome__property-info-section">
                              <h3>Trash & Recycling</h3>
                              <p>{propertyData.trashRecycling}</p>
                            </div>
                          )}
                        </AccordionDetails>
                      </Accordion>

                      {/* Add similar checks for Local Amenities, Guest Services, etc. */}
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel3-content"
                          id="panel3-header"
                        >
                          Local Amenities
                        </AccordionSummary>
                        <AccordionDetails>
                          {propertyData?.groceryStores && (
                            <div className="store-welcome__property-info-section">
                              <h3>Nearest Grocery Stores</h3>
                              <p>{propertyData.groceryStores}</p>
                            </div>
                          )}
                          {propertyData?.pharmacyLocations && (
                            <div className="store-welcome__property-info-section">
                              <h3>Pharmacy Locations</h3>
                              <p>{propertyData.pharmacyLocations}</p>
                            </div>
                          )}
                          {propertyData?.localRestaurants && (
                            <div className="store-welcome__property-info-section">
                              <h3>Local Restaurants</h3>
                              <p>{propertyData.localRestaurants}</p>
                            </div>
                          )}
                          {propertyData?.atmCurrency && (
                            <div className="store-welcome__property-info-section">
                              <h3>ATM & Currency Exchange</h3>
                              <p>{propertyData.atmCurrency}</p>
                            </div>
                          )}
                          {propertyData?.publicTransport && (
                            <div className="store-welcome__property-info-section">
                              <h3>Public Transportation</h3>
                              <p>{propertyData.publicTransport}</p>
                            </div>
                          )}
                        </AccordionDetails>
                      </Accordion>

                      {/* Guest Services Accordion */}
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel4-content"
                          id="panel4-header"
                        >
                          Guest Services
                        </AccordionSummary>
                        <AccordionDetails>
                          {propertyData?.taxiServices && (
                            <div className="store-welcome__property-info-section">
                              <h3>Taxi & Ride Services</h3>
                              <p>{propertyData.taxiServices}</p>
                            </div>
                          )}
                          {propertyData?.laundryServices && (
                            <div className="store-welcome__property-info-section">
                              <h3>Laundry Service</h3>
                              <p>{propertyData.laundryServices}</p>
                            </div>
                          )}
                          {propertyData?.groceryDelivery && (
                            <div className="store-welcome__property-info-section">
                              <h3>Grocery Delivery</h3>
                              <p>{propertyData.groceryDelivery}</p>
                            </div>
                          )}
                          {propertyData?.emergencyNumbers && (
                            <div className="store-welcome__property-info-section">
                              <h3>Emergency Numbers</h3>
                              <p>{propertyData.emergencyNumbers}</p>
                            </div>
                          )}
                        </AccordionDetails>
                      </Accordion>

                      {/* Neighborhood Information Accordion */}
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel5-content"
                          id="panel5-header"
                        >
                          Neighborhood Information
                        </AccordionSummary>
                        <AccordionDetails>
                          {propertyData?.localAttractions && (
                            <div className="store-welcome__property-info-section">
                              <h3>Local Attractions</h3>
                              <p>{propertyData.localAttractions}</p>
                            </div>
                          )}
                          {propertyData?.parks && (
                            <div className="store-welcome__property-info-section">
                              <h3>Parks & Outdoor Spaces</h3>
                              <p>{propertyData.parks}</p>
                            </div>
                          )}
                          {propertyData?.gyms && (
                            <div className="store-welcome__property-info-section">
                              <h3>Gyms or Fitness Centers</h3>
                              <p>{propertyData.gyms}</p>
                            </div>
                          )}
                        </AccordionDetails>
                      </Accordion>

                      {/* Safety & Security Accordion */}
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel6-content"
                          id="panel6-header"
                        >
                          Safety & Security
                        </AccordionSummary>
                        <AccordionDetails>
                          {propertyData?.buildingSecurity && (
                            <div className="store-welcome__property-info-section">
                              <h3>Building Security</h3>
                              <p>{propertyData.buildingSecurity}</p>
                            </div>
                          )}
                          {propertyData?.fireEscapePlan && (
                            <div className="store-welcome__property-info-section">
                              <h3>Fire Escape Plan</h3>
                              <p>{propertyData.fireEscapePlan}</p>
                            </div>
                          )}
                        </AccordionDetails>
                      </Accordion>

                      {/* Check-Out Instructions Accordion */}
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel7-content"
                          id="panel7-header"
                        >
                          Check-Out Instructions
                        </AccordionSummary>
                        <AccordionDetails>
                          {propertyData?.checkOutProcedure && (
                            <div className="store-welcome__property-info-section">
                              <h3>Check-Out Procedure</h3>
                              <p>{propertyData.checkOutProcedure}</p>
                            </div>
                          )}
                          {propertyData?.depositRefund && (
                            <div className="store-welcome__property-info-section">
                              <h3>Deposit Refund</h3>
                              <p>{propertyData.depositRefund}</p>
                            </div>
                          )}
                          {propertyData?.reviewRequest && (
                            <div className="store-welcome__property-info-section">
                              <h3>Review Request</h3>
                              <p>{propertyData.reviewRequest}</p>
                            </div>
                          )}
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default StoreWelcome;
